html {
  background-color: #2b333a;
}

body {
  margin: 0;
  padding: 0;
  background-color: #2b333a;
  color: #eee;
  padding-top: env(safe-area-inset-top);
  padding-top: constant(safe-area-inset-top);
}

button {
  cursor: pointer;
}

hr {
  border: none;
  border-top: 1px solid #49525a;
}

.highcharts-credits,
.highcharts-contextbutton {
  display: none !important;
}

input::selection {
  background: #1678c2;
  color: #fff;
}

.ui.toggle.checkbox input:focus:checked~.box:before,
.ui.toggle.checkbox input:focus:checked~label:before,
.ui.toggle.checkbox input:checked~.box:before,
.ui.toggle.checkbox input:checked~label:before {
  background-color: #1377af !important
}
.ui.toggle.checkbox .box:before, .ui.toggle.checkbox label:before {
  background-color: #222;
}

.ui.inverted.input > input {
  background-color: rgba(0,0,0,.15);
  color: #fff;
}
.ui.inverted.icon.input > i.icon {
  color: #fff;
}
.ui.inverted.labeled.input > .ui.basic.label {
  background-color: rgba(0,0,0,.35);
  color: #ccc;
}

.ui.modal > .header,
.ui.modal > .content {
  background: none;
}

.ui.dropdown.button,
.ui.dropdown.button.active {
  background: #000;
  color: #fff;
}
.ui.dropdown.button > .icon {
  border-right: 1px solid #333;
}
.ui.dropdown .menu {
  background: rgb(28, 36, 42);
}
.ui.dropdown .menu > .header,
.ui.dropdown .menu > .item {
  color: #ddd;
}
.ui.dropdown .menu > .item:hover {
  color: #fff;
}

/* semantic ui modal alignment bug fix */
.ui.page.modals.transition.visible {
  display: flex !important;
}
.ui.modal {
  margin-top: 0 !important;
}

.monospace,
.ui.monospace > input {
  font-family: monospace;
}

html { -webkit-tap-highlight-color: rgba(0,0,0,0); }

.small.text {
  font-size: .8em;
}

.faded.text {
  color: #999;
}
